'use client'

import Collection from '@/components/Collection'
import IconButton from '@/components/IconButton'
import Skeleton from '@/components/Skeleton'
import useTranslate from '@/hooks/useTranslate'
import { Collection as CollectionType } from '@/types/collection'
import classNames from 'classnames'
import { ArrowLeft, ArrowRight, I3Square } from 'iconsax-react'
import { PropsWithChildren, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { createUseStyles } from 'react-jss'
import 'swiper/css'
import { Autoplay, FreeMode, Keyboard } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import uniqid from 'uniqid'
import Status, { ProcessStatus } from '../Status'

export type CollectionCarouselProps = PropsWithChildren<{
  className?: string
  title?: string
  collections: CollectionType[]
  slidesPerView?: number
  slidesPerGroup?: number
  fixedWidth?: boolean
}>

const CollectionCarousel = ({
  className,
  collections,
  title,
  slidesPerView = 4,
  slidesPerGroup = 2,
  fixedWidth = true
}: CollectionCarouselProps) => {
  const classes = useStyles()
  const swiperRef = useRef<SwiperRef>(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [swiperLoaded, setSwiperLoaded] = useState(false)
  const [swiperEnded, setSwiperEnded] = useState(false)
  const handleNext = () => swiperRef.current?.swiper.slideNext()
  const handlePrev = () => swiperRef.current?.swiper.slidePrev()
  const t = useTranslate()

  return (
    <div className="">
      <div
        className={classNames(
          'mb-5 flex items-center justify-between font-firago sm:mb-7',
          className
        )}
      >
        <h2 className="text-base font-medium sm:text-xl">{title}</h2>
        {!!collections.length && (
          <div className="hidden sm:flex sm:items-center sm:gap-3">
            <IconButton
              type="ghost"
              size="small"
              icon={<ArrowLeft size={20} />}
              className={classNames(
                '!hover:border-black !border !bg-transparent',
                {
                  '!border-gray-300': !currentIndex || !swiperLoaded,
                  '!border-black': !!currentIndex
                }
              )}
              onClick={handlePrev}
              disabled={!currentIndex || !swiperLoaded}
            />
            <IconButton
              type="ghost"
              size="small"
              icon={<ArrowRight size={20} />}
              className={classNames(
                '!hover:border-black !border !bg-transparent',
                {
                  '!border-gray-300': swiperEnded || !swiperLoaded,
                  '!border-black': !swiperEnded
                }
              )}
              onClick={handleNext}
              disabled={swiperEnded || !swiperLoaded}
            />
          </div>
        )}
      </div>
      {!swiperLoaded && !!collections.length && (
        <div>
          <div className="flex w-[162vw] sm:w-full">
            {new Array(isMobile ? 2 : 4).fill(null).map((_, index) => (
              <Skeleton
                key={uniqid()}
                className={classNames(
                  '!w-[81vw] border-b-[1px] border-r-[1px] border-t-[1px] sm:w-full',
                  {
                    '!border-l-[0px]': index > 0,
                    '!border-l-[1px]': index === currentIndex
                  }
                )}
                type="collection"
              />
            ))}
          </div>
        </div>
      )}

      {!!collections.length && (
        <div
          className={classNames('-mx-5 sm:mx-0', {
            '!hidden': !swiperLoaded
          })}
        >
          <Swiper
            modules={[Keyboard, Autoplay, FreeMode]}
            className={classNames(classes.slider)}
            wrapperClass="!px-5 sm:!px-0 !w-initial lg:border-0"
            ref={swiperRef}
            onSlideChange={({ activeIndex, isEnd }) => {
              if (!isMobile) {
                setCurrentIndex(activeIndex)
                setSwiperEnded(isEnd)
              }
            }}
            speed={isMobile ? 100 : 600}
            keyboard
            direction="horizontal"
            grabCursor
            onInit={() => setSwiperLoaded(true)}
            slidesPerView="auto"
            spaceBetween={0}
            freeMode={true}
            cssMode={isMobile}
            autoplay={
              !isMobile && {
                delay: 4000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
                reverseDirection: false
              }
            }
            breakpoints={{
              1024: {
                rewind: true,
                slidesPerView,
                slidesPerGroup,
                freeMode: false
              }
            }}
          >
            {collections.map(({ name: title, authorName, products }, index) => (
              <SwiperSlide
                className={classNames(
                  '!h-auto border-l-[1px] border-gray-300 lg:border-0 [&:last-child>*]:border-r-[1px]',
                  {
                    'sm:!w-[325px]': fixedWidth
                  }
                )}
                key={uniqid()}
              >
                <Collection
                  title={title}
                  authorName={authorName}
                  products={products}
                  url="/"
                  onClick={() => null}
                  className={classNames('border-x-0 lg:border-x-[1px]', {
                    'lg:border-l-[0px]': index > 0,
                    'lg:border-l-[1px]': index === currentIndex
                  })}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}

      {!collections.length && (
        <div className="flex flex-col items-center justify-center py-8">
          <Status
            size="default"
            icon={<I3Square size="24" />}
            status={ProcessStatus.EMPTY}
          />
          <span className="text-sm mt-4">
            {t('common.no_collections_available')}
          </span>
        </div>
      )}
    </div>
  )
}

const useStyles = createUseStyles({
  slider: {
    '& .slick-track': {
      display: 'flex'
    }
  }
})

export default CollectionCarousel
