'use client'

import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { createUseStyles } from 'react-jss'
// eslint-disable-next-line import/order

export type SkeletonProps = PropsWithChildren<{
  className?: string
  type: 'product' | 'button' | 'image' | 'brand' | 'slider' | 'collection'
}>

const Skeleton = ({ className, type }: SkeletonProps) => {
  const classes = useStyles()

  if (type === 'product') {
    return (
      <div
        className={classNames('w-full border border-gray-300 p-8', className)}
      >
        <div
          className={classNames(
            'relative max-w-full overflow-hidden rounded-lg bg-gray-200 pb-[100%]'
          )}
        >
          <div className={classes.animation} suppressHydrationWarning></div>
        </div>
        <div className="relative mt-3.5 h-[15px] w-[80px] overflow-hidden rounded-lg bg-gray-200">
          <div className={classes.animation} suppressHydrationWarning></div>
        </div>
        <div className="relative mt-3 h-[18px] w-full overflow-hidden rounded-lg bg-gray-200">
          <div className={classes.animation} suppressHydrationWarning></div>
        </div>
        <div className="relative mt-3 h-[23px] w-[80px] overflow-hidden rounded-xl bg-gray-200">
          <div className={classes.animation} suppressHydrationWarning></div>
        </div>
      </div>
    )
  } else if (type === 'image') {
    return (
      <div
        className={classNames('relative h-full w-full bg-gray-200', className)}
      >
        <div className={classes.animation} suppressHydrationWarning></div>
      </div>
    )
  } else if (type === 'brand') {
    return (
      <div className={classNames('h-[80px] w-[197px] bg-gray-300', className)}>
        <div className={classes.animation} suppressHydrationWarning></div>
      </div>
    )
  } else if (type === 'slider') {
    return (
      <div
        className={classNames(
          'relative h-[180px] w-full overflow-hidden rounded-lg bg-gray-300 sm:h-[340px] sm:w-[1300px] sm:pb-0',
          className
        )}
      >
        <div className={classes.animation} suppressHydrationWarning></div>
        <div className="absolute left-6 top-1/2 z-10 h-9 w-9 -translate-y-1/2 rounded-full border-8" />
        <div className="absolute right-6 top-1/2 z-10 h-9 w-9 -translate-y-1/2 rounded-full border-8" />
      </div>
    )
  } else if (type === 'collection') {
    return (
      <div className={classNames('w-full p-4', className)}>
        <div className="grid h-[237px] grid-cols-3 gap-1 sm:!h-[194px]">
          <div className="relative pb-[100%]">
            <div className={classes.animation} suppressHydrationWarning></div>
          </div>
          <div className="relative pb-[100%]">
            <div className={classes.animation} suppressHydrationWarning></div>
          </div>
          <div className="relative pb-[100%]">
            <div className={classes.animation} suppressHydrationWarning></div>
          </div>
          <div className="relative pb-[100%]">
            <div className={classes.animation} suppressHydrationWarning></div>
          </div>
          <div className="relative pb-[100%]">
            <div className={classes.animation} suppressHydrationWarning></div>
          </div>
          <div className="relative pb-[100%]">
            <div className={classes.animation} suppressHydrationWarning></div>
          </div>
        </div>

        <div className="relative mt-3.5 h-[18px] w-[100px] overflow-hidden rounded-lg bg-gray-200">
          <div className={classes.animation} suppressHydrationWarning></div>
        </div>
        <div className="relative mt-2 h-[20px] w-[120px] overflow-hidden rounded-lg bg-gray-200">
          <div className={classes.animation} suppressHydrationWarning></div>
        </div>
      </div>
    )
  }

  return null
}

const useStyles = createUseStyles({
  animation: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage:
      'linear-gradient(90deg, #F1F2F4 25%, #E7E7E9 50%, #F1F2F4 75%)',
    backgroundSize: '200% 100%',
    animation: '$skeletonAnimation 1.8s infinite'
  },
  '@keyframes skeletonAnimation': {
    '0%': { backgroundPosition: '200% 0' },
    '100%': { backgroundPosition: '-200% 0' }
  }
})

export default Skeleton
