'use client'

import Image from '@/components/Image'
import Link from '@/components/Link'
import useTranslate from '@/hooks/useTranslate'
import { getProductThumbnail } from '@/utils/utils'
import classNames from 'classnames'
import { ArrowCircleRight2 } from 'iconsax-react'
import { useRouter } from 'next/navigation'
import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useWindowSize } from 'usehooks-ts'

export type CollectionProps = {
  url: string
  title: string
  authorName: string
  products: any[]
  className?: string
  onClick?: () => void
}

const Collection = ({
  title,
  authorName,
  products,
  className,
  url,
  onClick
}: CollectionProps) => {
  const { width } = useWindowSize()
  const isLarge = width >= 1280
  const [imageLoaded, setImageLoaded] = useState(false)
  const t = useTranslate()
  const router = useRouter()

  const handleClick = () => {
    onClick?.()
    router.push(url)
  }

  return (
    <div
      onClick={handleClick}
      className={classNames(
        'group h-full overflow-hidden border border-gray-300 p-4 font-firago relative',
        className
      )}
    >
      <button
        className={classNames('absolute bottom-4 right-4 z-10 ml-auto', {
          '-translate-x-2 transform opacity-0 transition-all ease-in-out group-hover:translate-x-0 group-hover:opacity-100 group-active:text-white':
            !!url
        })}
      >
        <ArrowCircleRight2 size="24" />
      </button>
      <div className="flex h-full w-full flex-col">
        {/* <div className="mb-3.5 h-[237px] p-0 sm:!h-[194px]"> */}
        <div className="mb-3.5 p-0">
          <div className="relative h-full rounded-lg">
            <div className="h-full w-full">
              <Link
                href={url}
                target="_blank"
                className="relative block h-full"
              >
                <div className="relative grid grid-cols-3 gap-1">
                  {products
                    .slice(0, products.length > 6 ? 5 : 6)
                    .map(({ id, assets = [], title: productTitle }) => (
                      <div
                        key={id}
                        className="relative overflow-hidden rounded-lg pb-[100%]"
                      >
                        <Image
                          src={getProductThumbnail(assets)}
                          alt={productTitle}
                          layout="fill"
                          objectFit="cover"
                        />
                      </div>
                    ))}

                  {products.length > 6 && (
                    <div className="flex items-center justify-center rounded-lg bg-gray-300 text-gray-400">
                      +{products.length - 5}
                    </div>
                  )}
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-auto px-0">
          <h5>
            <Link
              href="/"
              target="_blank"
              className="inline-block pb-1 !text-[11px] leading-[1.5] text-gray-400"
            >
              {t('common.user_collection', { name: authorName })}
            </Link>
          </h5>
          <h2>
            <Link href={url} target="_blank" className="block !text-sm">
              {title}
            </Link>
          </h2>
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({})

export default Collection
