'use client'

import useCart from '@/hooks/useCart'
import { Product as ProductType } from '@/types/product'
import {
  getAvailableQuantity,
  getDefaultVariant,
  getProductPrice,
  getProductThumbnail,
  isProductAvailable
} from '@/utils/utils'
import classNames from 'classnames'
import { memo, useMemo } from 'react'
import Product from '../Product'
import Skeleton from '../Skeleton'

type RawProductListProps = {
  products: ProductType[]
}

export const RawProductList = memo(({ products }: RawProductListProps) => {
  const { addToCart, addingToCart, cart } = useCart()

  const handleAddToCart = ({ variant_id }: any) => {
    const body = {
      variant_id,
      quantity: 1
    }

    addToCart(body as any)
  }

  const memoizedProducts = useMemo(() => products, [products])

  return (
    <div className="grid grid-cols-1 border-l border-t border-gray-300 sm:grid-cols-4">
      {memoizedProducts?.length === 0 &&
        new Array(8).fill(null)?.map((_, index) => (
          <Skeleton
            key={index}
            className={classNames('h-[419px] !border-l-0 !border-t-0 !pb-0', {
              'max-lg:hidden': index > 3
            })}
            type="product"
          />
        ))}
      {memoizedProducts?.map(
        ({
          id,
          title,
          brand,
          assets = [],
          price,
          discountPercentage,
          slug,
          variants
        }: ProductType) => {
          const availableQuantity =
            variants?.length === 1
              ? getAvailableQuantity(variants[0], cart!)
              : isProductAvailable({ variants })

          return (
            <Product
              id={id}
              key={id}
              brand={brand}
              title={title}
              addingToCart={addingToCart}
              price={getProductPrice({ variants }) ?? 0}
              isAvailable={!!availableQuantity}
              hasVariants={(variants?.length ?? 0) > 1}
              thumbnail={getProductThumbnail(assets)}
              discountPercentage={discountPercentage}
              url={`/products/${slug}`}
              className="!xl:pb-0 !border-l-0 !border-t-0 xl:h-[419px]"
              onAddToCart={() =>
                handleAddToCart({
                  variant_id: getDefaultVariant({ variants })?.id
                })
              }
            />
          )
        }
      )}
    </div>
  )
})
