'use client'

import classNames from 'classnames'
// eslint-disable-next-line import/order
import IconButton from '@/components/IconButton'
import Image from '@/components/Image'
import Link from '@/components/Link'
import Skeleton from '@/components/Skeleton'
import { useBreakpoint } from '@/hooks/useBreakpoints'
import { ArrowLeft, ArrowRight } from 'iconsax-react'
import { PropsWithChildren, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import 'swiper/css'
import {
  Autoplay,
  FreeMode,
  Keyboard,
  Navigation,
  Pagination
} from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import uniqid from 'uniqid'

export type Slide = {
  img: {
    lg: string
    sm: string
  }
  url: string
}

export type MainSliderProps = PropsWithChildren<{
  className?: string
  items: Slide[]
}>

const MainSlider = ({ className, items }: MainSliderProps) => {
  const classes = useStyles()
  const swiperRef = useRef<SwiperRef>(null)
  const [swiperLoaded, setSwiperLoaded] = useState(false)
  const handlePrev = () => swiperRef.current?.swiper.slidePrev()
  const handleNext = () => swiperRef.current?.swiper.slideNext()

  const { isMobile, isDesktop } = useBreakpoint()

  return (
    <div className={classNames('relative', className)}>
      {!items.length && !swiperLoaded && <Skeleton type="slider" />}
      {!!items.length && (
        <div className="-mx-5 sm:mx-0">
          <Swiper
            modules={[Keyboard, Autoplay, Pagination, Navigation, FreeMode]}
            onInit={() => setSwiperLoaded(true)}
            wrapperClass="!px-5 sm:!px-0 !w-initial"
            className={classNames(
              classes.slider,
              'overflow-hidden rounded-none sm:rounded-lg'
            )}
            ref={swiperRef}
            speed={isMobile ? 100 : 600}
            keyboard
            direction="horizontal"
            grabCursor
            freeMode={isMobile}
            cssMode={isMobile}
            spaceBetween={8}
            slidesPerView="auto"
            autoplay={
              isDesktop && {
                delay: 4000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
                reverseDirection: false
              }
            }
            pagination={{
              enabled: isDesktop,
              clickable: true
            }}
            breakpoints={{
              1024: {
                rewind: true,
                spaceBetween: 0,
                slidesPerView: 1,
                slidesPerGroup: 1,
                navigation: { enabled: true }
              }
            }}
          >
            {items.map(({ url, img }) => (
              <SwiperSlide
                key={uniqid()}
                className="!w-[335px] overflow-hidden rounded-lg sm:!w-full sm:rounded-none"
              >
                <Link href={url}>
                  <Image
                    src={img[isDesktop ? 'lg' : 'sm']}
                    alt=""
                    width={isDesktop ? 1300 : 335}
                    height={isDesktop ? 335 : 180}
                    layout="responsive"
                    placeholder="empty"
                    className="!min-w-full"
                  />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>

          {isDesktop && (
            <>
              <IconButton
                onClick={handlePrev}
                icon={<ArrowLeft size={20} />}
                size="small"
                className="absolute left-6 top-1/2 z-[9] -translate-y-1/2"
                type="secondary-dark"
              />
              <IconButton
                onClick={handleNext}
                icon={<ArrowRight size={20} />}
                size="small"
                className="absolute right-6 top-1/2 z-[9] -translate-y-1/2"
                type="secondary-dark"
              />
            </>
          )}
        </div>
      )}
    </div>
  )
}

const useStyles = createUseStyles({
  slider: {
    '& .swiper-pagination-bullet': {
      display: 'block',
      width: 16,
      height: 16,
      background: '#F1F2F4',
      borderRadius: 50,
      transition: '0.3s all ease-in-out',
      cursor: 'pointer',

      '&-active': {
        background: '#111722'
      }
    },
    '& .swiper-pagination': {
      display: 'flex',
      position: 'absolute',
      zIndex: 5,
      bottom: 24,
      left: '50%',
      transform: 'translateX(-50%)',
      gap: 10
    }
  }
})

export default MainSlider
