'use client'

import classNames from 'classnames'
import { PropsWithChildren, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
// eslint-disable-next-line import/order

export type SectionProps = PropsWithChildren<{
  className?: string
  title?: string
  addon?: ReactNode
  description?: string
}>

const Section = ({
  className,
  children,
  title,
  description,
  addon
}: SectionProps) => {
  const classes = useStyles()

  return (
    <div className={classNames(className)}>
      <div
        className={classNames(
          'mb-4 flex items-center justify-between font-firago sm:mb-7'
        )}
      >
        <div className="flex flex-col">
          <h2 className="text-base font-medium text-black sm:text-xl">
            {title}
          </h2>
          {!!description && (
            <h6 className="max-w-[417px] pt-4 text-sm text-black">
              {description}
            </h6>
          )}
        </div>
        {addon}
      </div>
      {children}
    </div>
  )
}

const useStyles = createUseStyles({})

export default Section
